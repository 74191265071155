<template>
  <div class="template" @click="clickTemplate">
    <span class="text">{{ template.text }}</span>
    <img
      class="image"
      v-if="!isAdd && template.fileType == 'image' && template.picture"
      :src="template.picture"
    />
    <div class="addWrap" v-if="isAdd">
      <label for="uploadM">
        <i class="iconfont icon-tianjia" />
      </label>
    </div>
    <div class="textAndBtn" v-if="!isAdd">
      <el-popconfirm
        :title="`确定${type == 'delete' ? '删除' : '使用'}吗？`"
        @confirm="templateClickHandler(type)"
      >
        <div class="btn" slot="reference">
          {{ type == "delete" ? "删除" : "使用" }}
        </div>
      </el-popconfirm>

      <el-popconfirm
        title="确定删除吗？"
        @confirm="deleteCommonTemplate(template.id)"
      >
        <div class="btn" v-if="deleteCommonTemplate" slot="reference">删除</div>
      </el-popconfirm>
    </div>
    <div class="addText" v-else>
      <label>添加素材</label>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isAdd() {
      return this.template.isAdd;
    },
  },
  props: {
    template: Object,
    clickHandler: Function,
    deleteTemplate: Function,
    type: String,
    deleteCommonTemplate: Function,
    useCommonTemplate: Function,
  },
  methods: {
    clickTemplate() {
      if (!this.isAdd) return;
      this.clickHandler();
    },
    templateClickHandler(type) {
      if (type == "delete") {
        this.deleteTemplate(this.template.id);
      } else {
        //使用模板
        this.useCommonTemplate(this.template.id, this.template.type);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.template {
  text-align: center;
  margin-bottom: 24px;
  border: 1px solid #e6e6e6;
  padding: 6px;
  border-radius: 8px;
  background-color: rgb(245, 247, 250);
  height: 246px;
  position: relative;
  .text {
    text-align: left;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 88%;
    margin: 0 0 8px 4px;
    overflow: hidden;
    display: block;
  }
  .image {
    width: 100%;
    height: 146px;
    border-radius: 10px;
  }
  .audioWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 116px;
    .iconfont {
      font-size: 40px;
    }
  }
  .addWrap {
    .iconfont {
      font-size: 36px;
      height: 116px;
      background-color: #e7e8e9;
      color: rgb(42, 130, 228);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 100%;
      cursor: pointer;
    }
  }
  .textAndBtn {
    display: flex;
    margin: 12px;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    .btn {
      border-radius: 2px;
      cursor: pointer;
      font-size: 14px;
      border-radius: 3px;
      color: #fff;
      background-color: #409eff;
      border-color: #409eff;
      padding: 0 20px;
      height: 30px;
      line-height: 30px;
      margin-left: 8px;
    }
  }
  .addText {
    color: rgb(42, 130, 228);
    margin: 12px 12%;
    text-align: left;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    #uploadM {
      display: none;
    }
  }
}
</style>